import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import mbh01 from '../img/mbh01.png';
import {apiUrl, useWindowDimensions} from "../App";
import dayjs from 'dayjs';
import 'dayjs/locale/sv' // import locale
import {Helmet} from "react-helmet-async";
import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm'
import svgArrowDown from "../img/arrow-down-short.svg"

dayjs.locale('sv') // use locale

export const ScrollToTopOnMount = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return null
}

export const EventTags = (props: any) => {
  const {tags} = props
  const nofTags = tags?.length ? tags.length : 0
  return (
    <h2>
      {tags?.map((tag: any, idx: number) => {
        if (idx === nofTags - 1) {
          return (<span className="title-case" key={tag.name + idx}>{tag.name}</span>)
        } else {
          return (<span className="title-case" key={tag.name + idx}>{tag.name}, </span>)
        }
      })}
    </h2>
  )
}

interface ITag {
  name: string,
}

interface IEvent {
  title: string,
  slug: string,
  ingress: string,
  description: string,
  date: string,
  published_date: string,
  tags: ITag[],
}

const toLocaleDateString = (dateString: string) => {
  // Use CSS to Title Case-transform this string
  return dayjs(dateString).format("dddd D MMMM kl HH:mm")
}

// Main page - list of events
export const Events = () => {

  // pagination - still downloads all events but this at least does not apply *all* images to the DOM
  // until the user clicks to show more events
  const [page, setPage] = useState(1)

  return (
    <main>
      <section className="upcoming-events">
        <h3 className="headline-left-aligned">Kommande</h3>
        <HighlightedEvents/>
      </section>

      <section className="bg-color-events">
        <h3 className="headline-left-aligned bg-color-events">Aktuellt</h3>
        <Ongoing/>
      </section>

      <section>
        <h3 className="headline-left-aligned">Arkiv</h3>
        <Archive page={page}/>
        {
          <div className="center">
            <button onClick={() => setPage(page + 1)} className="button-down">
              <img src={svgArrowDown}/>
            </button>
          </div>
        }
        <br/>
      </section>

      <PageMeta title="Verksamhet" description="På gång på Medborgarhuset"/>
    </main>
  )
}

/**
 * Detail page for an event.
 * TODO: Make one single component for event details and highlighted events
 * @constructor
 */
export const EventDetailView = () => {
  const {slug} = useParams()
  const [event, setEvent] = useState<IEvent | null>(null)
  const {width} = useWindowDimensions()
  const widthThreshold = 1024
  const isMobile = width <= widthThreshold

  useEffect(() => {
    const baseUrl = apiUrl()
    fetch(`${baseUrl}/api/event/${slug}/`)
      .then(response => response.json())
      .then(setEvent)
  }, [])

  return (
    <>
      <ScrollToTopOnMount />
      <div className="breadcrumbs">
        <Link to={"/"}>Verksamhet &gt; {event?.title}</Link>
      </div>

      <main>
        <section className="upcoming-events">

          <article>
            <div className={`text-content article-center-content wide-margin`}>
              <h1 className={`${isMobile ? "headline-overflow" : ""}`}><Link to={`/event/${event?.slug}`}>{event?.title}</Link></h1>

              <h2 className="title-case">{event?.date ? toLocaleDateString(event?.date) : ""}</h2>

              <br/>
              <EventImg event={event}/>
              <br/>

              <div className="article-left-content">
              {
                event?.description
                  ? <ReactMarkdown children={event.description} remarkPlugins={[remarkGfm]}/>
                  : null
              }
              </div>

            </div>
          </article>

          <EventMeta event={event}/>
        </section>
      </main>
    </>
  )
}

const MainEventImage = (props: any) => {
  const {event} = props
  let img = event?.cover_photo ? event.cover_photo : mbh01
  return (
    <div className="article-main-image">
      <Link to={`/event/${event?.slug}`}><img src={img} alt="medborgarhuset"/></Link>
    </div>
  )
}

// An event displayed with text to the left and big image to the right
const UpcomingEvent = (props: any) => {
  const {event} = props
  const {width} = useWindowDimensions()
  const widthThreshold = 1024
  const isMobile = width <= widthThreshold

  return (
    <article>

      <div className={`text-content article-center-content`}>
        <h1 className={`${isMobile ? "headline-overflow" : ""}`}><Link to={`/event/${event?.slug}`}>{event.title}</Link></h1>

        <h2 className="title-case">{event?.date ? toLocaleDateString(event?.date) : ""}</h2>
        <EventTags tags={event.tags}/>

        {/* HACK: pushing the image down with a br on mobile screens */}
        {width <= widthThreshold ? <><br/><MainEventImage event={event}/></> : null}

        <EventPresentationText event={event}/>
      </div>

      {width > widthThreshold ? <MainEventImage event={event}/> : null}

      {width <= widthThreshold ? <hr/> : null}

    </article>
  )
}

// Same as Upcoming events but with a different API URL.
// Shows event with text to the left and big image to the right
const HighlightedEvents = () => {
  const [events, setEvents] = useState<IEvent[] | null>(null)

  useEffect(() => {
    const baseUrl = apiUrl()
    fetch(`${baseUrl}/api/event/highlighted/`)
      .then(response => response.json())
      .then(setEvents)
  }, [])


  return (
    <>
      {events?.map((event, i) => {
        return (<UpcomingEvent key={event.slug + i} event={event}/>)
      })}
    </>
  )
}

/**
 * TODO: grid-auto-flow
 * @constructor
 */
const UpcomingEvents = () => {
  const [events, setEvents] = useState<IEvent[] | null>(null)

  useEffect(() => {
    const baseUrl = apiUrl()
    fetch(`${baseUrl}/api/event/upcoming/`)
      .then(response => response.json())
      .then(setEvents)
  }, [])


  return (
    <>
      {events?.map((event) => {
        return (<UpcomingEvent key={event.slug} event={event}/>)
      })}
    </>
  )
}

const Archive = (props: any) => {
  // holds all downloaded events
  const [events, setEvents] = useState<IEvent[] | null>(null)
  // pagination - still downloads all events but this at least does not apply *all* images to the DOM - they are loaded lazily
  // const [page, setPage] = useState(1)

  const {page} = props;

  useEffect(() => {
    const baseUrl = apiUrl()
    fetch(`${baseUrl}/api/event/archive/`)
      .then(response => response.json())
      .then(data => data?.reverse())
      .then(setEvents)
      .catch(error => console.warn(error))
  }, [])

  const eventsPerPage = 6;
  let someEvents = events?.slice(0, (page) * eventsPerPage);

  return (
    <GridOrBlock someEvents={someEvents}/>
  )
}

const Ongoing = (props: any) => {
  const [events, setEvents] = useState<IEvent[] | null>(null)

  useEffect(() => {
    const baseUrl = apiUrl()
    fetch(`${baseUrl}/api/event/ongoing/`)
      .then(response => response.json())
      .then(data => data?.reverse())
      .then(setEvents)
      .catch(error => console.warn(error))
  }, [])

  let someEvents = events?.slice(0, 6)
  return (
    <GridOrBlock someEvents={someEvents}/>
  )
}

const GridOrBlock = (props: any) => {
  const {someEvents} = props;
  return (
    <div className="grid-or-block">
      {
        someEvents?.map((event: IEvent, i: number) => {
          return (<ArchivedEvent key={event.slug + i} event={event}/>)
        })
      }
    </div>
  )
}

export const ArchivedEvent = (props: any) => {
  const {event} = props
  const {width} = useWindowDimensions()
  const widthThreshold = 1024
  const isMobile = width <= widthThreshold

  return (
    <article>
      <div className={`article-center-content`}>
        <h1 className={`${isMobile ? "headline-overflow" : ""}`}><Link to={`/event/${event.slug}/`}>{event.title}</Link></h1>
        <h2 className="title-case">{event?.date ? toLocaleDateString(event?.date) : ""}</h2>
        <EventTags tags={event.tags}/>
      </div>

      <EventImg event={event}/>

      <EventPresentationText event={event}/>
      <hr/>
    </article>
  )
}

const EventImg = (props: any) => {
  const {event} = props;

  return (
    event?.cover_photo ?
      <div className="cover-photo"><Link to={`/event/${event.slug}`}><img alt="alt-text coming soon"
                                                                          src={`${event.cover_photo}`}/></Link>
      </div> : null
  )
}

const EventPresentationText = (props: any) => {
  const {event} = props;
  const text = event.ingress ? event.ingress : event.description.split(".").slice(0, 4).join() + " ..."
  return (
    <div className="article-left-content">
      <ReactMarkdown children={text} remarkPlugins={[remarkGfm]}/>
    </div>
  )
}

const EventMeta = (props: any) => {
  const {event} = props
  const url = `https://medborgarhuset.se/event/${event?.slug}`
  return (
    <Helmet>
      <title>{event?.title}</title>
      <meta name="description" content={event?.ingress}/>

      <meta property="og:title" content={event?.title}/>
      <meta property="og:url" content={url}/>
      <meta property="og:image" content={event?.cover_photo}/>
    </Helmet>
  )
}

export const PageMeta = (props: any) => {
  const {title, description} = props;
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description}/>
      <meta property="og:title" content={title}/>
    </Helmet>
  )
}