import React, {useEffect, useState} from 'react';
import './App.css';
import {Route, Routes} from "react-router-dom";
import {Calendar, Page, Pages} from "./views/pages";
import {Rent} from "./views/rent";
import {EventDetailView, Events} from "./views/events";
import {MobileMenu} from "./components/mobile-menu";
import {DesktopMenu} from "./components/desktop-menu";
import {HelmetProvider} from "react-helmet-async";

export const apiUrl = (): string => {
  const {REACT_APP_API_URL} = process.env
  if (REACT_APP_API_URL === undefined) {
    return "http://localhost:8000"
    // return "https://medborgarhuset-app-ojuy9.ondigitalocean.app/backend"
  } else {
    return `${REACT_APP_API_URL}`
  }
}

export interface IPage {
  title: string,
  description: string,
  date: string,
  cover_photo: string,
}

export const pageEffect = (slug: string, setFn: any) => {
  let baseUrl = apiUrl()
  fetch(`${baseUrl}/api/page/${slug}/`)
    .then(response => response.json())
    .then(setFn)
}

interface IWindowDimensions {
  width: number,
  height: number,
}

const getWindowDimensions = (): IWindowDimensions => {
  const {innerWidth: width, innerHeight: height} = window
  return {width, height}
}

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return windowDimensions
}

const App = () => {
  const {width} = useWindowDimensions()
  const widthThreshold = 1024;
  return (
    <HelmetProvider>
      <div className="App">

        {width <= widthThreshold ? <MobileMenu/> : <DesktopMenu/>}

        <Routes>
          <Route path="/" element={<Events/>}/>
          <Route path="/events" element={<Events/>}/>
          <Route path="/event/:slug" element={<EventDetailView/>}/>
          <Route path="/calendar" element={<Calendar slug="kalender"/>}/>
          <Route path="/cinema" element={<Page slug="biograf"/>}/>
          <Route path="/about" element={<Pages slug="om-oss"/>}/>
          <Route path="/test" element={<Pages slug="test"/>}/>
          <Route path="/rent" element={<Rent/>}/>
          <Route path="/english" element={<Page slug="english"/>}/>
        </Routes>

        <footer>
          <div className="footer-element">
            <h1>HITTA HIT</h1>

            <p>Riksdalervägen 2<br/>
              129 32 Hägersten<br/>
              T-Bana Hägerstensåsen
            </p>
          </div>

          <div className="footer-element">
            <h1>KONTAKTA OSS</h1>
            <p>08 - 18 68 18<br/>
              <a href="mailto:boka@medborgarhuset.se">boka@medborgarhuset.se</a>
            </p>
          </div>

          <div className="footer-element">
            <h1>INFORMATION IN ENGLISH</h1>
            <p>
              <a href="/english">Find it here</a>
            </p>
          </div>

          <div className="footer-element">
            <h1>FÖLJ VÅRT ARBETE</h1>
            <p>
              <a
                href="https://www.facebook.com/pages/category/Arts---entertainment/H%C3%A4gerstens%C3%A5sens-Medborgarhus-2393092317402414/">Medborgarhuset
                på Facebook</a> <br/>
              <a href="https://www.instagram.com/medborgarhusethagersten/">Medborgarhuset på Instagram</a>
            </p>
          </div>
        </footer>
      </div>
    </HelmetProvider>
  )
}

export default App
