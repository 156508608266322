export const getBackgroundColor = (location: any) => {
  switch (location.pathname) {
    case "/about":
      return "bg-color-about"
    case "/rent":
      return "bg-color-rent"
    case "/english":
      return "bg-color-english"
    default:
      return "bg-color-events"
  }
}
