import "./mobile-menu.css"
import {useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {getBackgroundColor} from "./menu-utils";
import MBH_Logga_270522 from "../img/MBH_Logga_270522.svg"

interface LinkProps {
  url: string,
  name: string,
  handle: (val: boolean) => void,
}

export const MobileLink = (props: LinkProps) => {
  const {url, name, handle} = props;
  const location = useLocation()
  const isRoot = location.pathname === "/" && url === "/events"
  const isActive = location.pathname === url || isRoot
  return (
    <div className="mobile-nav-link" onClick={() => handle(false)}>
      <Link to={url} className={isActive ? "active-nav" : ""}>{name}</Link>
    </div>
  )
}

export const MobileMenu = () => {
  const location = useLocation()
  const [open, setOpen] = useState(false)
  const setDefaultOverflow = () => {
    document.body.style.overflowY = "visible"
  }

  return (
    <div className=".mobile-container">
      <div className={`mobile-header ${getBackgroundColor(location)}`}>
        <div className="flex-item-one"/>
        <h1 className="logo">

          <Link to="/">
            <img src={MBH_Logga_270522}/>
          </Link>

        </h1>
        <div className="hamburger-menu">
          <div className="mobile-nav-icon" onClick={() => {
            const isOpen = !open
            document.body.style.overflowY = isOpen ? "hidden" : ""
            setOpen(isOpen)
          }}>
            =
          </div>
        </div>
      </div>

      <div className="mobile-links" style={{display: open ? "" : "none"}}>

        <MobileLink url={"/events"} name={"Verksamhet"} handle={() => {
          setDefaultOverflow()
          setOpen(false)
        }}/>
        <MobileLink url={"/calendar"} name={"Kalender"} handle={() => {
          setDefaultOverflow()
          setOpen(false)
        }}/>
        <MobileLink url={"/cinema"} name={"Biograf"} handle={() => {
          setDefaultOverflow()
          setOpen(false)
        }}/>
        <MobileLink url={"/about"} name={"Om oss"} handle={() => {
          setDefaultOverflow()
          setOpen(false)
        }}/>
        <MobileLink url={"/rent"} name={"Hyr lokal"} handle={() => {
          setDefaultOverflow()
          setOpen(false)
        }}/>
      </div>
    </div>
  )
}
