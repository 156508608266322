import React, {useEffect, useState} from "react";
import {apiUrl} from "../App";
import {PageMeta} from "./events";
import remarkGfm from "remark-gfm";
import ReactMarkdown from "react-markdown";

interface IRoom {
  name: string,
  description: string,
  category: number,
  order: number,
  cover_photo: string,
}

export const Rent = (props: any) => {
  const [rooms, setRooms] = useState<IRoom[] | null>(null)
  const [text, setText] = useState<any>(null)

  useEffect(() => {
    const baseUrl = apiUrl()
    fetch(`${baseUrl}/api/room/`)
      .then(response => response.json())
      .then(data => data.results)
      .then(setRooms)

    fetch(`${baseUrl}/api/page/hyr-lokal/`)
      .then(response => response.json())
      .then(setText)
  }, [])

  return (
    <main>
      <section className="upcoming-events">
        <div className="article-center-content wide-margin">

          {
            text
              ? <ReactMarkdown children={text.description} remarkPlugins={[remarkGfm]}/>
              : null
          }

        </div>
      </section>

      <section>
        <h3 className="headline-left-aligned">Rum att hyra</h3>
        <div className="grid-or-block">
          {rooms?.map(room => <Room key={room.order} room={room}/>)}
        </div>
      </section>

      <PageMeta title="Hyr lokal" description="Medborgarhusets lokaler"/>
    </main>
  )
}

export const Room = (props: any) => {
  const {room} = props
  // TODO: This is a hack until backend returns correct url
  const coverPhoto = room?.cover_photo?.split("?")[0]

  return (
    <article>
      <div className="article-center-content">
        <h1>{room.name}</h1>
        <h2>{room.category}</h2>


        {
          coverPhoto ? <div className="cover-photo"><img alt="alt-text-coming-soon" src={coverPhoto}/></div> : null
        }

        <p className="text-align-left">{room?.description}</p>

      </div>
    </article>
  )
}
